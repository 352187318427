import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { BettingGameTypeEnum } from "../enum/betting-game-type.enum";
import { BettingGameInterface } from "../interface/betting-game.interface";
import { MemberService } from "src/app/core/member";
import { GameWinnerLoserInfo } from "../interface/betting-game-winner-loser-interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame`;

@Injectable({
    providedIn: "root",
})
export class BettingGameListService {
    /**
     * the current betting game subjects
     */
    private bettingGameListSubject: {
        [key in BettingGameTypeEnum]: BehaviorSubject<BettingGameInterface[]>;
    };

    /**
     * current loading states for lists
     */
    private isListLoading: {
        [key in BettingGameTypeEnum]: boolean;
    };

    /**
     * prepare the service
     *
     * @param httpService
     */
    constructor(
        // inject dependencies
        private httpService: HttpService,
        private memberService: MemberService,
    ) {
        // set betting game list subjects and states
        this.bettingGameListSubject = {
            [BettingGameTypeEnum.Subscribed]: new BehaviorSubject<BettingGameInterface[]>(null),
            [BettingGameTypeEnum.Promoted]: new BehaviorSubject<BettingGameInterface[]>(null),
            [BettingGameTypeEnum.Public]: new BehaviorSubject<BettingGameInterface[]>(null),
            [BettingGameTypeEnum.Owning]: new BehaviorSubject<BettingGameInterface[]>(null),
        };
        this.isListLoading = {
            [BettingGameTypeEnum.Subscribed]: false,
            [BettingGameTypeEnum.Promoted]: false,
            [BettingGameTypeEnum.Public]: false,
            [BettingGameTypeEnum.Owning]: false,
        };
    }

    /**
     * It returns an observable of all subscribed betting games
     *
     * @returns An observable of an array of BettingGameInterface objects.
     */
    public getSubscribedList(): Observable<BettingGameInterface[]> {
        return this.getList(BettingGameTypeEnum.Subscribed);
    }

    /**
     * It returns an observable of all promoted betting games
     *
     * @returns An observable of an array of BettingGameInterface objects.
     */
    public getPromotedList(): Observable<BettingGameInterface[]> {
        return this.getList(BettingGameTypeEnum.Public);
    }

    /**
     * It returns an observable of all public betting games
     *
     * @returns An observable of an array of BettingGameInterface objects.
     */
    public getPublicList(): Observable<BettingGameInterface[]> {
        return this.getList(BettingGameTypeEnum.Public);
    }

    /**
     * It returns an observable of all owning betting games
     *
     * @returns An observable of an array of BettingGameInterface objects.
     */
    public getOwningList(): Observable<BettingGameInterface[]> {
        return this.getList(BettingGameTypeEnum.Owning);
    }

    /**
     * It returns an observable that emits the list of betting games of the given type
     *
     * @param {BettingGameTypeEnum} type - BettingGameTypeEnum
     * @returns An observable that will emit the list of betting games of the given type.
     */
    public getList(type: BettingGameTypeEnum): Observable<BettingGameInterface[]> {
        // start a new laoding request and return it
        if (!this.isListLoading[type]) {
            this.bettingGameListSubject[type].next(null);
            this.loadListSubject(type);
        }
        // transform current subject into an observable and return it
        return this.bettingGameListSubject[type].asObservable().pipe(
            filter((games: BettingGameInterface[]) => games !== null),
            first()
        );
    }

    /**
     * It returns the number of subscribed games.
     *
     * @returns The number of games in the Subscribed list.
     */
    public getSubscribedCount(): number {
        return this.getListCount(BettingGameTypeEnum.Subscribed);
    }

    /**
     * It returns the number of promoted games.
     *
     * @returns The number of promoted games.
     */
    public getPromotedCount(): number {
        return this.getListCount(BettingGameTypeEnum.Promoted);
    }

    /**
     * It returns the number of public games.
     *
     * @returns The number of bets in the public list.
     */
    public getPublicCount(): number {
        return this.getListCount(BettingGameTypeEnum.Public);
    }

    /**
     * It returns the number of games the member owns
     *
     * @returns The number of games in the list of games that are of type Owning.
     */
    public getOwningCount(): number {
        return this.getListCount(BettingGameTypeEnum.Owning);
    }

    /**
     * It returns the length of the betting game list for the given betting game type
     *
     * @param {BettingGameTypeEnum} type - BettingGameTypeEnum
     * @returns The number of betting games in the list.
     */
    public getListCount(type: BettingGameTypeEnum): number {
        // load the list if there is no loaded list
        if (!this.isListLoading[type] && this.bettingGameListSubject[type].value === null) {
            this.loadListSubject(type);
        }
        // return the amount of games for this type
        return this.bettingGameListSubject[type].value !== null
            ? this.bettingGameListSubject[type].value.length
            : 0;
    }

    /**
     * It clears the list of all the betting game types.
     */
    public clear(): void {
        for (const key of Object.keys(BettingGameTypeEnum)) {
            this.clearList(BettingGameTypeEnum[key]);
        }
    }

    /**
     * It takes a type of betting game, and then sets the
     * betting game list subject for that type to null
     *
     * @param {BettingGameTypeEnum} type - BettingGameTypeEnum
     */
    public clearList(type: BettingGameTypeEnum): void {
        this.bettingGameListSubject[type].next(null);
    }

    /**
     * It loads the betting game list from the server and updates the betting game list subject
     *
     * @param {BettingGameTypeEnum} type - BettingGameTypeEnum
     */
    private loadListSubject(type: BettingGameTypeEnum): void {
        this.isListLoading[type] = true;

        this.httpService
            .get<BettingGameInterface[]>(`${apiUrl}/list/subscribed`)
            .pipe(
                first(),
                tap((games: BettingGameInterface[]) => {
                    this.bettingGameListSubject[type].next(games);
                    this.isListLoading[type] = false;
                })
            )
            .subscribe();
    }
   private loadAll(type: BettingGameTypeEnum): void {
        this.isListLoading[type] = true;
        this.httpService
            .get<BettingGameInterface[]>(`${apiUrl}/list/all`)
            .pipe(
                first(),
                tap((games: BettingGameInterface[]) => {
                    this.bettingGameListSubject[type].next(games);
                    this.isListLoading[type] = false;
                })
            )
            .subscribe();
    }
    public getAll(type: BettingGameTypeEnum): Observable<BettingGameInterface[]> {
        // start a new laoding request and return it
        if (!this.isListLoading[type]) {
            this.bettingGameListSubject[type].next(null);
            this.loadAll(type);
        }
        // transform current subject into an observable and return it
        return this.bettingGameListSubject[type].asObservable().pipe(
            filter((games: BettingGameInterface[]) => games !== null),
            first()
        );
    }
        public fetchListSubject(type: BettingGameTypeEnum): Observable<BettingGameInterface[]>{
            if (!this.isListLoading[type]) {
                this.bettingGameListSubject[type].next(null);
                this.loadListSubject(type);
            }
            // transform current subject into an observable and return it
            return this.bettingGameListSubject[type].asObservable().pipe(
                filter((games: BettingGameInterface[]) => games !== null),
                first()
            );
    }

   
}
