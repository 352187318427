// import { SidebarInitializer, SidebarService } from './sidebar';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AffiliateService, AfflilateInitializer } from './affiliate';
import { SeasonInitializer, SeasonService } from './season';
import { LabelInitializer, LabelService } from './label';
import { RouterModule } from '@angular/router';
import { TrackingInitializer, TrackingService } from './tracking';
import { SocialAuthenticationInitializer, SocialAuthenticationService } from './social';

/**
 * Core Module for the FrontModule
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule
    ],
    providers: [
        // social authentication initializer starts to listen on social logins
        { provide: APP_INITIALIZER, useFactory: SocialAuthenticationInitializer, multi: true, deps: [SocialAuthenticationService] },
        // label initalizer loads the label information depending on the current hostname
        { provide: APP_INITIALIZER, useFactory: LabelInitializer, multi: true, deps: [LabelService] },       
        // affiliate provider
        { provide: APP_INITIALIZER, useFactory: AfflilateInitializer, multi: true, deps: [AffiliateService] },
        // season provider
        { provide: APP_INITIALIZER, useFactory: SeasonInitializer, multi: true, deps: [SeasonService] },
        // tracking initalizer
        { provide: APP_INITIALIZER, useFactory: TrackingInitializer, multi: true, deps: [TrackingService] },
        // sidebar provider (depricated beceuse we have some static options now)
        // { provide: APP_INITIALIZER, useFactory: SidebarInitializer, multi: true, deps: [SidebarService] },
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('FrontCoreModule has already been loaded.');
        }
    }
}