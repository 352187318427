import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, filter, first, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { MemberService } from "../../member";
import { BettingGameInterface } from "../interface/betting-game.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame`;

@Injectable({
    providedIn: "root",
})
export class BettingGameService {
    /**
     * the betting game loading state
     */
    private isLoading: boolean;

    /**
     * the current betting game
     */
    private gameSubject: BehaviorSubject<BettingGameInterface>;

    /**
     * prepare the service
     *
     * @param httpService
     */
    constructor(
        // inject dependencies
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        this.isLoading = false;
        this.gameSubject = new BehaviorSubject<BettingGameInterface>(null);
    }

    /**
     * It creates a betting game.
     *
     * @param {BettingGameInterface} data - BettingGame data to save
     * @returns The created BettingGame object
     */
    public create(data: BettingGameInterface): Observable<BettingGameInterface> {
        return this.httpService.post<BettingGameInterface>(`${apiUrl}/create`, { bettingGame: data });
    }

    /**
     * It updates the betting game.
     *
     * @param {any} data - any - this is the data that will be sent to the server.
     * @returns The updated BettingGame object
     */
    public update(data: any): Observable<BettingGameInterface> {
        return this.httpService.post<BettingGameInterface>(`${apiUrl}/update`, { bettingGame: data }).pipe(
            tap((bettingGame: BettingGameInterface) => {
                const member = this.memberService.getMemberSnapshot();
                this.gameSubject.next({
                    ...bettingGame,
                    owner: {
                        memberId: member.memberId,
                        username: member.username,
                    },
                });
            })
        );
    }

    /**
     * It deletes a betting game from the database.
     *
     * @param {string} uuid - The unique identifier of the betting game.
     * @returns An observable of type BettingGameInterface
     */
    public delete(uuid: string): Observable<BettingGameInterface> {
        return this.httpService.post<BettingGameInterface>(`${apiUrl}/delete`, { uuid: uuid });
    }

    /**
     * It returns an observable that emits the current betting game
     *
     * @param {boolean} [notNull=true] - boolean = true
     * @returns An observable of the gameSubject.
     */
    public getObservable(notNull: boolean = true): Observable<BettingGameInterface> {
        return this.gameSubject.asObservable().pipe(
            filter((gameSubject: BettingGameInterface) => {
                return notNull ? gameSubject !== null : true;
            })
        );
    }

    /**
     * It takes a bettingGameId, and returns an Observable of type BettingGameInterface
     *
     * @param {number} bettingGameId - number - the id of the betting game you want to load
     * @returns Observable<BettingGameInterface>
     */
    public loadById(bettingGameId: number): Observable<BettingGameInterface> {

        const url = `${apiUrl}/get/id/` + bettingGameId;
        return this.loadGameSubject(url);
    }

    /**
     * It loads a game by its UUID.
     *
     * @param {string} uuid - The UUID of the game you want to load.
     * @returns Observable<BettingGameInterface>
     */
    public loadByUUID(uuid: string): Observable<BettingGameInterface> {
        const url = `${apiUrl}/get/uuid/` + uuid;
        return this.loadGameSubject(url);
    }

    /**
     * If the game is not currently loading, we start to load the game data from the API and set the
     * gameSubject to null. If the game is currently loading, we return the next observable data which
     * is not null
     *
     * @param {string} url - string - the url to the betting game
     * @returns The gameSubject is being returned.
     */
    private loadGameSubject(url: string): Observable<BettingGameInterface> {
        // if the game is currently not loading, we start to load
        if (!this.isLoading) {
            // set loading to true and set the gameSubject to null
            // before we start to load the new betting game data
            this.isLoading = true;
            this.gameSubject.next(null);
            // load the betting game data from the api
            return this.httpService.get<BettingGameInterface>(url).pipe(
                first(),
                catchError((error: any) => {
                    // set loading to false on any error
                    this.isLoading = false;
                    return error;
                }),
                tap((game: BettingGameInterface) => {
                    // set the game subject with the received data
                    // and set the loading state to false
                    this.gameSubject.next(game);
                    this.isLoading = false;
                })
            );
        }
        // return the next observable data which is not null
        return this.gameSubject.asObservable().pipe(
            filter((game: BettingGameInterface) => game !== null),
            first()
        );
    }
}
