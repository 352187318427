import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { GameWinnerLoserInfo, Participation } from "src/app/front/core/betting-game/interface/betting-game-winner-loser-interface";

@Component({
    selector: "modal-bet-loser",
    templateUrl: "./modal-bet-loser.component.html",
    styleUrls: ["./modal-bet-loser.component.scss"],
})
export class ModalBetLoserComponent extends ModalComponent {
    /**
     * a short summary of all lose games
     */
    public bettingGameSummaries: GameWinnerLoserInfo;

    // Declaring a public property 'bettingGameName' of type string
    public bettingGameName: string;

    // Declaring a public property 'place of user' of type number
    public placeUser: number;

    /**
     * Represents the total amount of coins or currency accumulated in the jackpot for the betting game.
     */
    public jackpot: number;

    /**
     * A concatenated string of usernames of the winners of the betting game.
     */
    public winnerUserName: string;

    /**
     * The total number of participants who took part in the betting game.
     */
    public participants: number;

    /**
     * An array holding the participation details for each winner, including their respective positions and other relevant data.
     */
    public winners: Participation[];

    /**
     * Holds the data related to a member.
     * This includes the username and a picture URL, which may include a timestamp query parameter
     * to ensure the latest image is used (avoiding cache issues).
     */
    public memberdata: {
      username: string;
      picture: string;
    };

    private memberSubscription: Subscription;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected memberService: MemberService,
        protected translationService: TranslationService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ bettingGameSummaries: [] });
        this.setBettingData();
    }

    public ngOnInit(): void {
      this.observeLoginState();
  }
    private observeLoginState(): void {
      this.memberSubscription = this.memberService
          .getMemberObservable()
          .pipe(
              tap((member) => {
                  if (member) {
                    this.memberdata = {
                      username: member.username,
                      picture: this.getPicture(member.picture, member.changeDate),
                  };
                  } else {
                      this.data = null;
                  }
              })
          )
          .subscribe();

  }
    /**
     * reload the member on win open
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    }

    /**
     * Sets the betting game data from the provided data object.
     */
    private setBettingData(): void {
      // Assign the betting game summaries from the data object to the component's property
      this.bettingGameSummaries = this.data.bettingGameSummaries;
      // Extract the usernames of all winners and store them in an array
      const winnerUserNames: string[] = this.bettingGameSummaries.winners.map(winner => winner.member.username);
      // Join the array of winner usernames into a single string separated by commas
      this.winnerUserName = winnerUserNames.join(', ');
      // Set the jackpot amount from the betting game summaries
      this.jackpot = this.bettingGameSummaries.totalJackpot;
      // Set the name of the betting game
      this.bettingGameName = this.bettingGameSummaries.bettingGame.name;
      // Set the total number of participants in the betting game
      this.participants = this.bettingGameSummaries.participants;
      // Set the user's position in the betting game
      this.placeUser = this.bettingGameSummaries.placement;
      // Set the array of winners with their participation details
      this.winners = this.bettingGameSummaries.winners;
    }

    /**
     * returns the picture to display via css or an empty string
     *
     * @param picturePath
     * @param changeDate
     */
    private getPicture(picturePath?: string, changeDate?: Date): string {
      return picturePath
          ? "url(" + environment.api.requestImages + picturePath + "?t=" + changeDate.getTime() + ")"
          : "";
    }

    public ngOnDestroy(): void {
      this.memberSubscription.unsubscribe();
    }
}
