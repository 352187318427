import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { MemberInterface, MemberStatistcsInterface } from '../interface/member.interface';


/**
 * Basic Api Url for statistic actions
 */
const apiStaticUrl = `${environment.api.request}/member/statistic`;


@Injectable({
    providedIn: 'root'
})
export class StatisticService {
    /**
     * member (to observe if requested member changed)
     */
    private member: MemberInterface;

    /**
     * the current statistic subject
     */
    private statisticSubject: BehaviorSubject<MemberStatistcsInterface>;

    /**
     * current loading state
     */
    private isLoading: boolean = false;


    /**
     * prepare the service
     * 
     * @param httpService
     * @param memberService 
     */
    constructor(
        private httpService: HttpService
    ) {
        // set statistic subject
        this.statisticSubject = new BehaviorSubject<MemberStatistcsInterface>(null);
    }

    /**
     * returns an observable wich returns the statistic
     * 
     * @param member 
     */
    public getStatistic(member: MemberInterface): Observable<MemberStatistcsInterface> {
        // start a new laoding request and return it
        if (!this.isLoading && member) {
            this.statisticSubject.next(null);
            this.member = member;
            return this.load();
        }
        // transform current statistic into an observable and return it
        return this.statisticSubject.asObservable().pipe(
            filter(stats => stats != null),
            first()
        );
    }


    /**
     * loads member statistic data from api
     */
    private load(): Observable<MemberStatistcsInterface> {
        this.isLoading = true;
        const encodedUsername = encodeURIComponent(this.member.username);
        return this.httpService.get<MemberStatistcsInterface>(`${apiStaticUrl}/` + encodedUsername).pipe(
            tap((statistic) => {
                this.statisticSubject.next(statistic);
                // this.updatedAt = new Date();
                this.isLoading = false;
            }));
    }
}
