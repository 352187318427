<ng-template #modal>
  <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
      <div class="glamour-container animate__animated animate__flipInX">
          <div class="starrays gold"></div>
          <span class="tiger-sprite huge-icon chips-4-noshadow"></span>
      </div>
  </div>
  <!-- <span class="tiger-sprite huge-icon rank-1"></span> -->
  <div class="modal-body pb-5">
      <div class="row">
          <div class="col-12 col-sm-11 mx-auto text-center">
              <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">
                  {{ 'account.bets.slip.modalWin.title' | translate }}
              </h1>
          </div>
          <div class="col-12 col-sm-10 mx-auto text-center animate__animated animate__fadeInUp animate__delay-500ms">
              <p class="subline mb-4">{{ 'account.bets.slip.modalWin.message' | translate: {betSlips: betSlipIds.length } }}</p>
              <div class="card gain duo animate__animated animate__fadeInUp animate__delay-500ms">
                  <div class="row">
                      <div class="col-12 col-sm-6">
                          <span class="gain-label"><img src="/assets/images/coin-1.png">
                              +<app-shared-countup [to]="coins" [duration]="2.5" [delay]="0.5"></app-shared-countup>
                              {{ 'coins' | translate }}
                          </span>
                      </div>
                      <div class="col-12 col-sm-6">
                          <span class="gain-label"><img src="/assets/images/xp-1.png">
                              +<app-shared-countup [to]="xp" [duration]="2.5" [delay]="0.5"></app-shared-countup>
                              {{ 'xp' | translate }}
                          </span>
                      </div>
                  </div>
             </div>
          </div>

          <div class="col-12 col-sm-11 mx-auto text-center animate__animated animate__fadeInUp animate__delay-1500ms">
              <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">
                  {{ 'account.bets.slip.modalWin.button' | translate: {betSlips: betSlipIds.length } }}
              </button>
              <p class="text-center mt-2 d-block d-md-none d-lg-none d-xl-none"><span class="cursor-pointer" (click)="modalDismiss()">{{ 'close' | translate }}</span></p>
          </div>
      </div>
  </div>
</ng-template>
