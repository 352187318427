<form class="form-background" [formGroup]="creationFormular" (submit)="onSubmit()">
  <div style="background: transparent;" class="header-creation modal-header content-header">
    <div style="width: 100%;text-align: center;">
      <h5 style="text-align: center;" class="modal-title" id="exampleModalLabel">{{
        'bettingGame.dashboard.creation.headline' | translate }}</h5>
    </div>

    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">
        <i class="fas fa-times" style="font-size:24px;">
        </i>
      </span>
    </button>
  </div>
  <div style="font-size: 12px;" class="modal-body">
    <fieldset>
      <div class="form-group">
        <label class="font-weight-bold" for="betting-game-name">{{ 'bettingGame.dashboard.creation.namethegame' |
          translate }}</label>
        <input style="color:white;" type="text" class="form-control" id="betting-game-name" formControlName="name">
      </div>
    </fieldset>

    <div class="row">
      <div class="col align-self-center">
        <!-- <switch formControlName="isPublic" [rightValue]="false" [leftValue]="true"
          rightLabel="{{ 'bettingGame.dashboard.creation.public' | translate }}"
          leftLabel="{{ 'bettingGame.dashboard.creation.private' | translate }}"></switch> -->
      </div>
      <div class="col align-self-center">
        <button type="submit" class="btn btn-primary btn-lg shadow"
          style="font-size: 14px; border-radius: 100px; width: 200px">
          <span style="margin-right: 15px;" *ngIf="maximumPlayersCount === 'UNLIMITED'">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="20" viewBox="0 0 65 35" fill="none">
              <path
                d="M27.4421 10.0315C25.1569 6.8825 21.5063 4.82031 17.2984 4.82031C10.3036 4.82031 4.61862 10.5052 4.61862 17.5C4.61862 24.4947 10.3036 30.1796 17.2984 30.1796C22.008 30.1796 26.3831 27.6994 28.8076 23.6587L32.514 17.5L36.1925 11.3412C38.6169 7.30043 42.9921 4.82031 47.7017 4.82031C54.6964 4.82031 60.3814 10.5052 60.3814 17.5C60.3814 24.4947 54.6964 30.1796 47.7017 30.1796C43.5216 30.1796 39.871 28.1175 37.558 24.9684"
                stroke="#292D32" stroke-width="8.28023" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span style="color: black;">{{ maximumPlayersCount }}</span>
        </button>
      </div>
    </div>

    <fieldset>
      <slider class="w-100" formControlName="playersLimit" name="playersLimit"></slider>
    </fieldset>

    <fieldset>
      <div class="form-group">
        <label class="font-weight-bold" for="betting-game-decsription">{{
          'bettingGame.dashboard.creation.describethegame' | translate }}</label>
        <textarea style="color:white;" rows="1" class="form-control" id="betting-game-description"
          formControlName="description"></textarea>
      </div>
    </fieldset>
    <div *ngIf="error" class="text-center">{{ error|translate }}</div>

    <div style="color: white !important;" class="d-flex align-items-center" formArrayName="leagues"
      *ngFor="let selectLeague of leagueFormControls.controls; let i = index; last as isLast; first as isFirst;"
      style="gap: 10px;">
      <fieldset style="color:white;" class="mb-4 flex-grow-1">
        <div style="color:white;" class="form-group">
          <label style="color:white;" for="betting-game-league" class="font-weight-bold">{{
            'bettingGame.default.league.label' |
            translate }} {{i+1}}</label>
          <select style="color:white;" id="betting-game-league" class="form-control" [formControlName]="i">
            <ng-container *ngIf="!leaguesSelect; else leagueOptions">
              <option value="0">{{ 'loading' | translate }}</option>
            </ng-container>
            <ng-template #leagueOptions>
              <!-- ALL TOP LEAGUES -->
              <optgroup label="TOP">
                <option *ngFor="let league of leaguesSelect.popular | keyvalue: orderOrigin"
                  value="{{ league.value.leagueId }}">
                  {{ league.value.leagueName }}
                </option>
              </optgroup>
              <!-- ALL LEAGUES GROUPED BY SPORT AND LOCATION -->
              <ng-container *ngFor="let sport of leaguesSelect.sports | keyvalue: orderOrigin">
                <optgroup *ngFor="let location of sport.value.locations | keyvalue: orderByName"
                  label="{{ sport.value.sportName }} - {{ location.value.locationName }}">
                  <option *ngFor="let league of location.value.leagues | keyvalue: orderByName"
                    value="{{ league.value.leagueId }}">
                    {{ league.value.leagueName }}
                  </option>
                </optgroup>
              </ng-container>
            </ng-template>
          </select>
        </div>
      </fieldset>
      <button style="width: 40px;" class="btn btn-secondary" *ngIf="!isFirst"
        (click)="removeLeagueControl(i)">-</button>
      <button style="width: 40px;" class="btn btn-secondary" *ngIf="isLast && i < 5"
        (click)="addLeagueControl()">+</button>
    </div>

    <div class="form-group">
      <label class="font-weight-bold" for="betting-game-date">{{ 'Select a Start Date' }}</label>
      <input type="text" class="form-control" bsDatepicker [placement]="['top']"  formControlName="startDate" id="betting-game-date" style="color:white;" placeholder="Select date">
      <label class="font-weight-bold" for="betting-game-date">{{ 'Select an End  Date' }}</label>
      <input type="text" class="form-control" bsDatepicker [placement]="['top']"  formControlName="endDate" id="betting-game-date" style="color:white;" placeholder="Select date">
    </div>  

    <div class="d-flex justify-content-between">
      <fieldset>
        <div style="display: flex;" class="form-group">
          <!-- <label style="margin-right: 15px;" class="font-weight-bold" for="betting-game-name">Game mode</label>
                    <switch class="small-font" formControlName="isJackpot" rightLabel="Free" leftLabel="Jackpot"></switch> -->
          {{ 'bettingGame.dashboard.creation.onejackpot' | translate }}
        </div>
        <!-- <div style="display: flex;">
                <label class="font-weight-bold" for="betting-game-name">Abzeichen für</label>
                <div style="margin-left: 10px;" class="form-group">
                    <div  class="form-check">
                        <input   class="form-check-input" formControlName="winMode" type="checkbox" id="winmode-checkbox-3" [value]="3" checked>
                        <label     class=" form-check-label" for="winmode-checkbox-3">
                            <span class="small-font  	">first place</span>
                        </label>
                    </div>
                    <div  class="form-check">
                        <input class="form-check-input" formControlName="winMode" type="checkbox" id="winmode-checkbox-5" [value]="5">
                        <label class=" form-check-label" for="winmode-checkbox-5">
                            <span class="small-font  	">first 3 places</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" formControlName="winMode" type="checkbox" id="winmode-checkbox-10" [value]="10">
                        <label class="form-check-label" for="winmode-checkbox-10">
                          <span class="small-font  	">first 5 places</span>
                        </label>
                    </div>
                </div>
            </div> -->
      </fieldset>
      <fieldset>
        <div class="d-flex gap-10">
        </div>
      </fieldset>
    </div>
    <div class="creation-line"></div>
    <!-- Cost Calculation-->
    <div class="coins500 d-flex">
      <span style="color: white; margin-top: -35px;" class="coins-cost">500
        <span style="padding-left: 10px">{{'bettingGame.dashboard.creation.coins' | translate }}
        </span>
      </span>
    </div>
    <div class="fz-13 fw-400" style="width: 100%; display: flex; justify-content: center;">{{
      'bettingGame.dashboard.creation.jackpotfee' | translate }}</div>
  </div>

  <div class="modal-footer">
    <button class="btn-secondary fz-24 fw-900"
      style="text-transform: uppercase; border-radius: 100px; width:200px; background-color: transparent;" type="button"
      data-dismiss="modal" (click)="close()">{{
      'bettingGame.dashboard.creation.button.cancel' | translate }}</button>
    <button style="width: 300px;" type="submit" class="special-button" [disabled]="!canSubmit()">
      <div style="font-size: 15px;" class="special-button-text">{{
        'bettingGame.dashboard.creation.button.save' | translate }}</div>
    </button>
  </div>
</form>