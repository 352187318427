import { Injectable } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { SocialAuthenticationService } from '../service/social-authentication.service';
import { MemberService } from '../../../../core/member';
import { ToastrService } from '../../../../shared/toastr';
import { ModalController, ModalEventType, ModalService, ModalTutorial } from '../../../shared/modal';
import { TranslationService } from '../../../../core/translation';
import { first } from 'rxjs/operators';
import { IsVerifying } from '../IsVerifying';

@Injectable({
    providedIn: 'root'
})
export class SocialLoginService {

    constructor(
        protected socialAuthentiactionService: SocialAuthenticationService,
        protected socialAuthService: SocialAuthService,
        protected memberService: MemberService,
        protected translationService: TranslationService,
        protected toastrService: ToastrService,
        protected modalService: ModalService
    ) {}

    
    /**
     * listen for login with a google account
     */
     public listenForLogin(): void {
        this.handleSocialLogin();
    }

    /**
     * start to login with a facebook account
     */
    public loginByFacebook(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    /**
     * handles the social login by open a loading modal
     * and waiting for and verifying the response
     * 
     * @param providerId 
     */
    private handleSocialLogin(): void {
        // start to listen on the verification events to handle the login modals
        let modalController: ModalController;
        const loginSubscriber = this.socialAuthentiactionService.isVerifyingObservable().subscribe((verificationState: IsVerifying) => {
            // if there is no open loading modal and we started to verify, we need to open the loading modal and close the login modal
            if (!modalController && verificationState.isVerifying) {
                this.modalService.closeAll();
                modalController = this.modalService.openLoading('login.loading.modal');
            }
            // if the modalController is set (the loading modal is open) and we are not verifying anymore,
            // we need to close the loading modal and unsubscribe the 
            else if (modalController && !verificationState.isVerifying) {
                modalController.closeModal({ delay: 250 });
                loginSubscriber.unsubscribe();
                this.handleSocialLoginVerify();
            }
        });
    }

    /**
     * handles the new verification state
     */
    private handleSocialLoginVerify(): void {
        if (this.socialAuthentiactionService.getIsVerified()) {
            this.handleSocialLoginSuccess();
        } else {
          //  this.modalService.openError();
        }
    }

    /**
     * reloads the member
     */
    private handleSocialLoginSuccess(): void {
        this.loginSuccessNotify();
        this.memberService.loginRefresh().subscribe();
        // get the verification
        const verification = this.socialAuthentiactionService.getVerification();
        // if this was also a registration we open the success modal
        if (verification?.registration) {
            const modalController = this.modalService.openDefault({
                title: { text: 'register.success.title', parameter: { app: verification?.appname } },
                message: { text: 'register.success.message', parameter: { coins: this.translationService.formatNumber(+verification?.bonus) } },
                buttonConfirm: 'register.success.button',
                memberReload: true
            })
            .onModalEvent(ModalEventType.Closed)
                .pipe(first())
                .subscribe(() => this.modalService.open(new ModalTutorial()));
        }
    }

    /**
     * shows the login success toastr notification
     */
    private loginSuccessNotify(): void {
        setTimeout(() => this.toastrService.success({
            title: 'login.success.title',
            message: 'login.success.message'
        }), 250);
    }
}
