import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { MemberInterface } from "../../member";
import { BettingGameInvitationInterface } from "../interface/betting-game-invitation.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame/invitation`;

@Injectable({
    providedIn: "root",
})
export class BettingGameInvitationService {
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * It takes a uuid and a search string, and returns an array of MemberInterface objects
     *
     * @param {string} uuid - The uuid of the current betting game
     * @param {string} search - The search string
     * @returns An array of MemberInterface objects.
     */
    public autocomplete(uuid: string, search: string): Observable<MemberInterface[]> {
        const url = apiUrl + "/autocomplete";
        return this.httpService.post<MemberInterface[]>(url, {
            uuid: uuid,
            search: search,
        });
    }

    /**
     * It takes a uuid and a code, and returns the betting game invitation
     *
     * @param {string} uuid - The uuid of the user to validate.
     * @param {string} code - The code that the user entered.
     * @returns A boolean value.
     */
    public load(uuid: string, code: string): Observable<BettingGameInvitationInterface> {
        const url = apiUrl + "/get/" + uuid + "/" + code;
        return this.httpService.get<BettingGameInvitationInterface>(url);
    }

    /**
     * It takes a uuid and a code, and send an invitation accept request
     * to the api
     *
     * @param {string} uuid - The uuid of the user you want to accept.
     * @param {string} code - The code that the user entered.
     * @returns A boolean value.
     */
    public accept(uuid: string, code: string): Observable<boolean> {
        const url = apiUrl + "/accept";
        return this.httpService
            .post<{ success: boolean }>(url, {
                uuid: uuid,
                code: code,
            })
            .pipe(map((response: { success: boolean }) => response.success));
    }
    public join(id: number,uuid: string,): Observable<boolean> {
        const url = apiUrl + "/join";
        return this.httpService
            .post<{ success: boolean }>(url,  { id: id, uuid: uuid })
            .pipe(map((response: { success: boolean }) => response.success));
    }

    /**
     * It sends an invite to the server with the uuid and invitation.
     *
     * @param {string} uuid - The uuid of the betting game.
     * @param {string | number} invite - The memberId or email to use for the invite
     * @returns A boolean value.
     */
    public invite(uuid: string, invite: string | number): Observable<boolean> {
        const url = apiUrl + "/create";
        return this.httpService
            .post<{ success: boolean }>(url, {
                uuid: uuid,
                invite: invite,
            })
            .pipe(map((response: { success: boolean }) => response.success));
    }
}
