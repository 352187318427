<ng-template #modal>
  <div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalDismiss()">
      <span aria-hidden="true">
        <i class="fas fa-times" style="font-size: 24px; padding-right: 10px; padding-top: 10px;">
        </i>
      </span>
    </button>
  </div>
  <img class="modal-header-image">
  <div class="profile-avatar"
    [ngStyle]="{'background-image': memberdata.picture ? memberdata.picture : 'url(/assets/images/avatar.png)'}">
  </div>
  <div class="modal-body pb-5">
    <div class="row">
      <div class="col-12 col-sm-11 mx-auto text-center">
        <p class="tigerfont header animate__animated animate__fadeInUp animate__delay-500ms">
          {{ 'account.bets.slip.modalWinner.title' | translate }}
        </p>
      </div>
      <div class="col-12 mx-auto text-center animate__animated animate__fadeInUp animate__delay-500ms">
        <p class="message">{{ 'account.bets.slip.modalWinner.message' | translate: {bettingName: bettingGameName } }}
        </p>
        <p class="sub-message">{{ 'account.bets.slip.modalWinner.subMessage' | translate }}
        </p>
        <div class="row">
          <div class="col-12">
            <span class="gain-coins">
              <app-shared-countup [to]="coins" [duration]="2.5" [delay]="0.5"></app-shared-countup>
              <span style="color: #FFA025;"> {{ 'coins' | translate }}</span>
            </span>
          </div>
        </div>
        <p *ngIf="winners && winners.length > 1" class="sub-message">{{ 'account.bets.slip.modalWinner.jackpot' |
          translate: {
          countWinners: winners.length } }}
        </p>
        <p class="sub-message">{{ 'account.bets.slip.modalWinner.participants' | translate: {numberPlayers:
          participants} }}</p>
      </div>

      <div class="col-12 mx-auto center-button text-center animate__animated animate__fadeInUp animate__delay-1500ms"
        style="padding-left:0%">
        <button type="submit" class="special-button button-thanks" (click)="modalButton()">
          <div style="font-size: 15px;" class="special-button-text">
            {{'account.bets.slip.modalWinner.button' | translate }}
          </div>
        </button>
        <p class="text-center mt-2 d-block d-md-none d-lg-none d-xl-none"><span class="cursor-pointer"
            (click)="modalDismiss()">{{ 'close' | translate }}</span></p>
      </div>
    </div>
  </div>
</ng-template>
