<ng-template #modal>
    <div class="video-ad-header">
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body pb-5" *ngIf="!lock?.isLocked && !this.isVideoAdComplete && (!videoAdUrl || isLoadingIFrame)">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                {{ 'videoAds.prepare.loading' | translate }} 
            </div>
        </div>
    </div>    

    <div class="modal-body pb-5" *ngIf="lock?.isLocked || this.isVideoAdComplete">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center"  *ngIf="!this.isVideoAdComplete">
                <h2 class="tigerfont text-uppercase mb-3">{{ 'videoAds.lock.title' | translate }}</h2>
            </div>       
        </div>
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center" *ngIf="this.isVideoAdComplete">
                {{ 'videoAds.complete.message' | translate:{ coins: rewardCoins } }} 
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center" *ngIf="lock?.isLocked">
                {{ 'videoAds.lock.message' | translate }} <br />
                <h3>{{ this.lockTimer.minutes }}:{{ this.lockTimer.seconds }}</h3>
            </div>
            <button *ngIf="lock?.isLocked" type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">{{ 'videoAds.lock.button' | translate }}</button>
            <button *ngIf="!lock?.isLocked" type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="onNextVideoAd()">{{ 'videoAds.complete.button' | translate }}</button>
        </div>
    </div>
    <iframe class="video-ads-iframe" [class]="isLoadingIFrame ? 'hidden' : ''" (load)="isLoadingIFrame = false" *ngIf="videoAdUrl" [src]="videoAdUrl | safeUrl" (click)="onMessageEvent($event)"></iframe>
</ng-template>