import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { IsVerifying } from '../IsVerifying';
import { AuthenticationService } from 'src/app/core/security';


/**
 * Basic Api Url for login
 */
const loginUrl = `${environment.api.request}/auth/login`;

@Injectable({
    providedIn: 'root'
})
export class SocialAuthenticationService {
    /**
     * indicates if an social login was successfull at the first
     * place and is now verifying against the api to receive a token
     */
    private isVerifying: BehaviorSubject<IsVerifying>;

    /**
     * indicates if the login was verified by our api
     */
    private isVerified: boolean;

    /**
     * stores the verification result
     */
    private verification: { token: string, registration?: boolean, appname?: string, bonus?: number };


    constructor(
        private httpService: HttpService,
        private socialAuthService: SocialAuthService,
        private authenticationService: AuthenticationService
    ) {
        this.isVerifying = new BehaviorSubject<IsVerifying>({ isVerifying: false });
    }

    /**
     * we use the initialization to start listen on the authState
     * of the @angularx-social-login so we can notice login and
     * of changes
     */
    public async onAppInitialization(): Promise<void> {
        // this.socialAuthService.authState.subscribe(async (socialUser: SocialUser) => {
        //     // if social user is null, the user logged out but we dont handle that
        //     // we just log the user in once and then we work with our own token.
        //     // Because of that, we only handle the data if its not null
        //     if (socialUser) {
        //         await this.verifySocialLogin(socialUser);
        //     }
        // });
        // this.authenticationService.getCurrentAccessToken().then((idToken) => {
        //     this.verifyYoureToken(idToken)
        // }).catch((err) => { console.error(err) })

    }

    /**
     * the observable is true if the social login (at goole for example)
     * was complete and we are now verifying the data with our api
     * 
     * @returns 
     */
    public isVerifyingObservable(): Observable<IsVerifying> {
        return this.isVerifying.asObservable();
    }

    /**
     * return true if the login was verified by the server
     * 
     * @returns 
     */
    public getIsVerified(): boolean {
        return this.isVerified;
    }

    /**
     * returns true if the last verification was also a registration
     * 
     * @returns 
     */
    public getVerification(): { token: string, registration?: boolean, appname?: string, bonus?: number } {
        return this.verification;
    }

    /**
     * verifies the socialUser with the api to receive
     * our authentication token
     * 
     * @param socialUser 
     */
    private verifySocialLogin(socialUser: SocialUser): void {
        this.verification = null;
        this.isVerified = false;
        this.isVerifying.next({ providerId: socialUser.provider, isVerifying: true });
        const verifyLoginUrl = loginUrl + '/' + socialUser.provider.toLocaleLowerCase();
        this.httpService.post<{ token: string, registration?: boolean, appname?: string, bonus?: number }>(verifyLoginUrl, { socialUser: socialUser }).subscribe({
            next: (verification: { token: string, registration?: boolean, appname?: string, bonus?: number }) => {
                this.verification = verification;
                this.isVerified = true;
                this.isVerifying.next({ providerId: socialUser.provider, isVerifying: false });
            },
            error: (error: any) => {
                this.isVerifying.next({ providerId: socialUser.provider, isVerifying: false });
            }
        });
    }

    public verifyYoureToken(token?: string) {
        this.verification = null;
        this.isVerified = false;
        this.isVerifying.next({ providerId: "YOURE", isVerifying: true });
        const verifyLoginUrl = loginUrl + '/youre'
        this.httpService.post<{ token: string, registration?: boolean, appname?: string, bonus?: number }>(verifyLoginUrl, { token: token }).subscribe({
            next: (verification: { token: string, registration?: boolean, appname?: string, bonus?: number }) => {
                this.verification = verification;
                this.isVerified = true;
                this.isVerifying.next({ providerId: "YOURE", isVerifying: false, token:verification.token });
            },
            error: (error: any) => {
                this.isVerifying.next({ providerId: "YOURE", isVerifying: false });
            }
        });
    }
}
