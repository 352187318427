import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { AffiliateService } from "../../affiliate";
import { AuthenticationService } from "../../../../core/security";
import { TrackingService } from "../../tracking";
import { HttpService } from "../../../../core/http";
import { TranslationService } from "src/app/core/translation";

/**
 * Basic Api Url for member actions
 */
const apiMemberUrl = `${environment.api.request}/member`;

@Injectable({
    providedIn: "root",
})
export class RegistrationService {
    /**
     * setup dependencies, init auth variables and subscripbe to events
     *
     * @param authService
     * @param affiliateService
     * @param trackingService
     * @param httpService
     */
    constructor(
        private authService: AuthenticationService,
        private affiliateService: AffiliateService,
        private trackingService: TrackingService,
        private httpService: HttpService,
        private translationService: TranslationService
    ) {}

    /**
     * register a mamber and set login on success
     *
     * @param memberdata
     */
    public register(memberdata: any,token:any): Observable<{ token: string; appname: string; bonus: string }> {
        return this.httpService
            .post<{ token: string; appname: string; bonus: string }>(
                `${apiMemberUrl}`, { member: memberdata, },{token:token}, true, {"chosen-language": this.translationService.currentLang },
            )
            .pipe(
                tap((data: { token: string; appname: string; bonus: string }) => {
              
                    this.trackingService.trackRegistration();
                    this.authService.setLoginToken(data.token);
                    this.affiliateService.reset();
                })
            );
    }
}
