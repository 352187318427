import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { first, tap } from "rxjs";
import { HttpService } from "../../../../core/http";
import { MemberService as CoreMemberService } from "../../../../core/member";
import { AuthenticationService } from "../../../../core/security";
import { WebSocketDataEventInterface, WebSocketEventEnum, WebSocketService } from "../../../../core/websocket";
import { MemberInterface } from "../interface/member.interface";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class MemberService extends CoreMemberService {
    /**
     * setup the service
     *
     * @param httpService
     * @param cookieService
     * @param authService
     * @param webSocketService
     * @param httpClient
     */
    constructor(
        protected httpService: HttpService,
        protected cookieService: CookieService,
        protected authService: AuthenticationService,
        protected webSocketService: WebSocketService,
    ) {
        super(httpService, cookieService, authService);
        this.observeWallet();
    }

    /**
     * This function subscribes to a websocket event and updates the member's coins when the event is
     * triggered.
     */
    private observeWallet(): void {
        this.webSocketService.getDataObservable(
            WebSocketEventEnum.MemberWalletUpdate
        ).subscribe((event: WebSocketDataEventInterface) => {
            if (this.hasMember()) {
                const member = this.getMemberSnapshot();
                member.progress.coins = +event.data.coins;
                this.setMember(member);
            }
        });
    }

    public updateMember(newValues: Pick<MemberInterface, "language">) {
        const member = this.getMemberSnapshot();
        return this.httpService
            .post<MemberInterface>(this.getApiUrl() + "/" + member.memberId, newValues)
            .pipe(
                tap(newMember => { this.setMember(newMember) })
            );
    }

    public saveSessionDuration(currentTime: number, lastVisibilityChangeTime: number, platform: string) {
        const member = this.getMemberSnapshot();
    
        const sessionData = { 
            dt: new Date(lastVisibilityChangeTime).toISOString(),
            dt_end: new Date(currentTime).toISOString(),
            memberId: member.memberId, 
            score: member.progress.winLadder.winLadderId, 
            platform: platform 
        };
    
        const token = this.authService.getToken();
    
        return this.httpService.post<any>(this.getApiUrl() + "/session", sessionData)
            .pipe(
                catchError(error => {
                    console.error('Error:', error);
                    return throwError(error);
                })
            );
    }
    
}
