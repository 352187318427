import { Component, HostListener } from "@angular/core";
import moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { VideoAdsLockInterface, VideoAdsService } from "../../../../core/ads";
import { MemberInterface, MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-payment",
    templateUrl: "./modal-videoads.component.html",
    styleUrls: ["./modal-videoads.component.scss"],
})
export class ModalVideoAdsComponent extends ModalComponent {
    /**
     * indicates the iFrame loading status
     */
    public isLoadingIframe: boolean = true;

    /**
     * waiting for iFrame url from api
     */
    public isLoadingUrl: boolean = true;

    /**
     * member lock status
     */
    public isLoadingLock: boolean = true;

    /**
     * true after a completed ad
     */
    public isVideoAdComplete: boolean = false;

    /**
     * status of the next button (if a video ad was complete)
     */
    public isNextButtonEnabled: boolean = false;

    /**
     * url for the payment iFrame
     */
    public videoAdUrl: string;

    /**
     * members video lock information
     */
    public lock: VideoAdsLockInterface;

    /**
     * time until unlock
     */
    public lockTimer: { minutes: string; seconds: string } = { minutes: "00", seconds: "00" };

    /**
     * number of coins added as reward
     */
    public rewardCoins: number;

    /**
     * subscribe for lock changes
     */
    private lockSubscription: Subscription;

    /**
     * subscribe for lock changes
     */
    private memberSubscription: Subscription;

    /**
     * rd parsed from iFrameUrl
     */
    private rd: string;

    /**
     * timer interval
     */
    private timerInterval: ReturnType<typeof setInterval> | null;

    /**
     * inject dependencies
     *
     * @param memberService
     * @param videoAdsService
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected memberService: MemberService,
        protected videoAdsService: VideoAdsService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
    }

    /**
     * listen on events from Ads iFrame
     *
     * @param event
     */
    @HostListener("window:message", ["$event"])
    public onMessageEvent(event: any) {
        const origin = event.origin || event.originalEvent.origin;
        const dataString = event.data + "";
        const evtData = dataString.split("~");
        const status = evtData[0];
        const hash = evtData[1];
        this.handleEventResponse(status, hash, origin);
    }


    /**
     * subscrive lock information and member on open
     *
     * @param data
     */
    public onOpen(data: any): void {
        // load the currnet lock for the member
        this.lockSubscription = this.videoAdsService.getLockedObservable().subscribe({
            next: (lock: VideoAdsLockInterface) => this.onLockSubsciption(lock),
            error: (resposne) => this.handleError("unexpected"),
        });
        // auto close the modal if the user logs out
        this.memberSubscription = this.memberService
            .getMemberObservable()
            .pipe(filter((member: MemberInterface) => member === null))
            .subscribe((member: MemberInterface) => {
                setTimeout(() => this.close(), 500);
            });
    }

    /**
     * unsubscribe lock information on unsucbscribe
     *
     * @param data
     */
    public onClose(data: any): void {
        this.lockSubscription.unsubscribe();
        this.stopTimer();
    }

    /**
     * reset the modal when the modal becomes hidden
     *
     * @param data
     */
    public onHidden(data: any): void {
        this.reset();
    }

    /**
     * on lock subscrition data
     *
     * @param lock
     */
    private onLockSubsciption(lock: VideoAdsLockInterface): void {
        const previousLock = this.lock;
        this.isLoadingLock = false;
        this.lock = lock;
        if ((!previousLock || previousLock.isLocked) && !this.lock.isLocked) {
            this.setVideoAdUrl();
        }
        if (!previousLock?.isLocked && this.lock.isLocked) {
            this.startTimer();
        } else if (this.lock?.isLocked && !this.lock.isLocked) {
            this.stopTimer();
        }
    }

    /**
     * start next video through button click
     */
    public onNextVideoAd(): void {
        this.reset();
        this.videoAdsService.getVideoAdLock(true).subscribe();
    }

    /**
     * set video ad url for the iFrame
     * and refresh the member (and login)
     */
    private setVideoAdUrl(): void {
        this.memberService
            .loginRefresh()
            .pipe(tap((member: MemberInterface) => this.loadVideoUrl()))
            .subscribe();
    }

    /**
     * tries to load the video url via the service
     * and sets the values to the modal data
     */
    private loadVideoUrl(): void {
        this.videoAdsService.getVideoAdUrl().subscribe({
            next: (url: string) => {
                // setup url
                this.isLoadingUrl = false;
                this.videoAdUrl = url;
                // parse rd from url
                const matchRd = /rd=([0-9a-z]+)/i.exec(url);
                this.rd = matchRd[1] || "";
            },
            error: (response) => this.handleError(response.error.data),
        });
    }

    /**
     * handles the iFrame response by checking the status
     *
     * @param status
     * @param hash
     * @param origin
     */
    private handleEventResponse(status: string, hash: string, origin: string): void {
        switch (status) {
            // ad complete
            case "adcomplt":
                this.handleVideoAdComplete(hash, origin);
                break;

            case "limitmax":
            case "noaddisp":
            case "plyerror":
                this.handleVideoAdError(status);
                break;

            default:
                // do nothing, just events we dont care about
                break;
        }
    }

    /**
     * send video ad complete to api and handles the result
     */
    public handleVideoAdComplete(hash: string, origin: string): void {
        this.videoAdsService.postSuccess(this.rd, hash, origin).subscribe({
            next: (coins: number) => {
                this.videoAdsService.getVideoAdLock(true).subscribe();
                this.memberService.reload().subscribe();
                this.isVideoAdComplete = true;
                this.videoAdUrl = null;
                this.rewardCoins = coins;
            },
            error: (response) => this.handleError(response.error.data),
        });
    }

    /**
     * report video error
     */
    public handleVideoAdError(status: string): void {
        this.videoAdsService.postError(status).subscribe({
            next: (data) => {},
            error: (response) => {},
        });
        this.handleError("unexpected");
        this.reset();
    }

    /**
     * handles response errors
     *
     * @param error
     */
    private handleError(error: string): void {
        switch (error) {
            case "videoAds.error.limit":
                this.handleLimitReachedError();
                break;

            case "videoAds.error.notFound":
                this.handleRewardNotFoundError();
                break;

            // like member not found or invalid hash
            default:
                this.handleUnexpectedError();
                break;
        }
    }

    /**
     * if limit reached, we reload the lock
     * information
     */
    private handleLimitReachedError(): void {
        this.isLoadingLock = true;
        this.videoAdsService.getVideoAdLock(true).subscribe();
    }

    /**
     * does currently nothing, but...
     */
    private handleRewardNotFoundError(): void {
        //console.warn("VIDEO ADS: REWARD NOT FOND");
    }

    /**
     * close the modal and and open
     * an error modal
     */
    private handleUnexpectedError(): void {
        this.close({ delay: 150 });
        setTimeout(() => {
            this.modalController.getModalService().openError("videoAds.error.unexpected");
        }, 150);
    }

    /**
     * starts the countdown timer
     */
    private startTimer() {
        this.stopTimer();
        this.setLockTimer();
        this.timerInterval = setInterval(() => {
            this.setLockTimer();
        }, 1000);
    }

    /**
     * stops the countdown timer
     */
    private stopTimer() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    /**
     * set the lock timer information (minutes and seconds until the lock is over)
     */
    private setLockTimer(): void {
        // get date to handle as moments
        const endMoment = moment(this.lock.lockedUntil);
        const currentMoment = moment();
        // get diff as minutes
        let diff = endMoment.diff(currentMoment);
        const minutes = Math.floor(moment.duration(diff).asMinutes());
        // get diff (minus the diff minutes) as seconds
        endMoment.subtract(minutes, "minutes");
        diff = endMoment.diff(currentMoment);
        const seconds = Math.floor(moment.duration(diff).asSeconds());
        // if the count down is over, stop the interval
        if (minutes === 0 && seconds === 0) {
            this.stopTimer();
        }
        // set lock timer values (with leading 0 if necessary)
        this.lockTimer.minutes = minutes < 10 ? "0" + minutes : "" + minutes;
        this.lockTimer.seconds = seconds < 10 ? "0" + seconds : "" + seconds;
    }

    /**
     * reset states
     */
    private reset(): void {
        this.lock = null;
        this.videoAdUrl = null;
        this.isLoadingUrl = true;
        this.isLoadingIframe = true;
        this.isVideoAdComplete = false;
    }
}
