import { Injectable } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { MemberInterface, MemberService } from "../../../front/core/member";
import moment from "moment";

// setting up locales for bootstrap
import { defineLocale } from "ngx-bootstrap/chronos";
import { deLocale } from "ngx-bootstrap/locale";
import { BehaviorSubject, Observable } from "rxjs";
defineLocale("de", deLocale);

@Injectable({
    providedIn: "root",
})
export class TranslationService extends TranslateService {
    /**
     * currently used context so we can choose the corrct dictionary
     */
    private context: "tigerking" | "tcp" = "tigerking";

    /**
     * we keep track of the languages we have allready loaded
     * so we can avoid to initialze the language again
     */
    private initializedLanguages: string[] = [];

    /**
     * Creating a BehaviorSubject that will hold the current language
     */
    private currentLanguageSubject: BehaviorSubject<string>;

    /**
     * This function returns an observable that emits the current
     * language whenever it changes.
     *
     * @returns An observable of the current language.
     */
    public getCurrentLanguageObservable(): Observable<string> {
        return this.currentLanguageSubject.asObservable();
    }

    /**
     * set language to use (and make the choice permanent)
     *
     * @param language
     */
    public async setLanguage(language: string): Promise<string> {
        return this.useLanguage(language);
    }

    /**
     * If the language is found in the array of available languages, return true, otherwise return false.
     *
     * @param language
     * @returns
     */
    public hasLanguage(language: string): boolean {
        const searchResult = this.getLangs().find((lang: string) => lang === language);
        return typeof searchResult !== "undefined";
    }

    /**
     * returns the default language
     */
    public getFallbackLanguage(): string {
        return environment.translation.fallback;
    }

    /**
     * This function sets the context of the language to either tigerking or tcp, and then uses the
     * current language to load the correct dictionary.
     *
     * @param context
     */
    public async setContext(context: "tigerking" | "tcp" = "tigerking"): Promise<void> {
        this.context = context;
        this.initializedLanguages = [];
        await this.useLanguage(this.getDefaultLang(), true);
        await this.useLanguage(this.currentLang, true);
    }

    /**
     * returns the preffered user language
     * detected by navigator
     */
    public getBrowserLanguage(): string | null {
        if (!environment.translation.useBrowserLanguage) {
            return null;
        }
        return navigator.languages
            ? navigator.languages[0]
            : //@ts-ignore because of userLanguage
            navigator.language || navigator.userLanguage;
    }

    /**
     * formats a number (decimal and group seperators) and returns it as string
     *
     * @param number
     * @param roundTo
     * @param language
     */
    public formatNumber(number: number, roundTo?: number, language?: string): string {
        const lang = language || this.currentLang;
        // get number to format
        const toFormat = roundTo ? number.toFixed(roundTo) : number;
        //  return formated string
        return toFormat.toLocaleString(lang);
    }

    /**
     * returns the (current) date formated
     * for the current language/locale
     *
     * @param date
     * @returns
     */
    public formatDate(date?: Date): string {
        const toFormat = date ? date : new Date();
        const format = this.getDateformat();
        return moment(toFormat).format(format);
    }

    /**
     * returns a number seperator (group or decimal) for the
     * currently used or passed language
     *
     * NOTE: I would prefer using the the intl functions for
     * this task but sadly angular fires an error if I try
     * to use it
     *
     * @param type
     * @param language
     */
    public getNumberSeparator(type: "decimal" | "group", language?: string): string {
        const lang = language || this.currentLang;
        return this.getNumberSeparatorByIntl(type, lang) || this.getNumberSeparatorByRegEx(type, lang);
    }

    /**
     * returns the date format like "DD.MM.YYYY"
     * for the current or passed language
     *
     * @param language
     */
    public getDateformat(language?: string): string {
        const lang = language || this.currentLang;
        const locale = moment.locale();

        moment.locale(lang);
        const localeData = moment.localeData();
        const format = localeData.longDateFormat("L");
        moment.locale(locale);
        return format;
    }

    /**
     * init the app lamguage by locale storage, member language, browser or fallback
     *
     * @param memberService
     */
    public async onAppInitialization(memberService: MemberService): Promise<boolean> {
        // set all supported languages (we load the translations on request)
        this.addLangs(environment.translation.languages);

        // setup the fallback language and locale
        const fallbackLang: string = this.getFallbackLanguage();
        await this.initializeLocaleModule(fallbackLang);
        await this.initializeLanguage(fallbackLang);
        this.setDefaultLang(fallbackLang);
        // initalize the language subject with the fallback language
        this.currentLanguageSubject = new BehaviorSubject<string>(fallbackLang);

        // set detected language
        await this.setDetectedLanguage();
        // subscribe member changes to set the members language
        memberService
            .getMemberObservable()
            .pipe(filter((member: MemberInterface) => member !== null))
            .subscribe((member: MemberInterface) => {
                this.setDetectedLanguage(member.language);
            });

        // just return true
        return true;
    }

    /**
     * It imports the locale module for the given language and registers it with the Angular framework
     *
     * @param {string} language - string - the language to load
     */
    private async initializeLocaleModule(language: string): Promise<void> {
        let moduleImportPromise: any;
        switch (language) {
            case "de":
                moduleImportPromise = import("@angular/common/locales/de");
                break;

            case "en":
                moduleImportPromise = import("@angular/common/locales/en");
                break;

            case "es":
                moduleImportPromise = import("@angular/common/locales/es");
                break;

            case "fr":
                moduleImportPromise = import("@angular/common/locales/fr");
                break;

            case "it":
                moduleImportPromise = import("@angular/common/locales/it");
                break;

            case "nl":
                moduleImportPromise = import("@angular/common/locales/nl");
                break;

            case "pl":
                moduleImportPromise = import("@angular/common/locales/pl");
                break;

            default:
                moduleImportPromise = import("@angular/common/locales/de");
                break;
        }
        const module = await moduleImportPromise.then((m: any) => m.default);
        registerLocaleData(module);
    }

    /**
     * loads a new language by importing the dictionary
     * to use and set up the translation
     *
     * @param language
     * @returns
     */
    private async initializeLanguage(language: string): Promise<boolean> {
        return new Promise((resolve) => {
            import("src/assets/i18n/" + this.context + "." + language + ".json")
                .then((translations) => {
                    this.setTranslation(language, translations);
                    this.initializedLanguages.push(language);
                    resolve(true);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    /**
     * returns true if the language ia allready initialzed
     *
     * @param language
     * @returns
     */
    private isLanguageInitialized(language: string): boolean {
        const searchResult = this.initializedLanguages.find((lang: string) => lang === language);
        return typeof searchResult !== "undefined";
    }

    /**
     * use the given language and if the language is not initialized we need to do this first and if that fail,
     * we switch to the default language
     *
     * @param {string} language - string - the language to use
     * @param {boolean} [force=false] - boolean = false
     * @returns The language that is being used.
     */
    private async useLanguage(language: string, force: boolean = false): Promise<string> {
        // nothing to do, if we allready use this language
        if (!force && this.currentLang === language) {
            return this.currentLang;
        }
        // if the language is not available we cant load or use it,
        // so we switch to the default language
        if (!this.hasLanguage(language)) {
            return this.useLanguage(this.detectLanguage());
        }
        // if the language is not initialized we need to do this first
        if (!this.isLanguageInitialized(language)) {
            // and if that fail, we switch to the default language
            if (!(await this.initializeLanguage(language))) {
                return this.useLanguage(this.detectLanguage());
            }
            // otherwise we try initialize the matching locale
            await this.initializeLocaleModule(language);
        }

        // set the language to use and return it
        this.use(language);
        this.setLocalStorageLanguage(language);
        this.currentLanguageSubject.next(language);
        return language;
    }

    /**
     * set language by detection chain
     *
     * @param {string} [memberLanguage] - This is the language that the user has set in their profile.
     * @returns The language that is being detected and used
     */
    private async setDetectedLanguage(memberLanguage?: string): Promise<string> {
        const userLang: string = this.detectLanguage(memberLanguage);
        return this.useLanguage(userLang);
    }

    private detectLanguage(memberLanguage?:string){
        return memberLanguage ||
        this.getLocalStorageLanguage() ||
        //get the first part (en-US) 
        this.getBrowserLanguage().split("-")[0] ||
        this.getFallbackLanguage();
    }
    /**
     * returns language from local storage
     */
    private getLocalStorageLanguage(): string | null {
        if (environment.translation.useLocalStorage) {
            return localStorage.getItem(environment.translation.localStorageKey);
        }
        return null;
    }

    /**
     * stores language in local storage
     *
     * @param language
     */
    private setLocalStorageLanguage(language: string) {
        if (environment.translation.useLocalStorage) {
            localStorage.setItem(environment.translation.localStorageKey, language);
        }
    }

    /**
     * it tries to get the number separator from the Intl object, but if it can't, it returns an empty
     * string.
     *
     * @param type
     * @param language
     * @returns
     */
    private getNumberSeparatorByIntl(type: "decimal" | "group", language: string): string {
        // number to do magic with ;)
        const toFromat = 1000.1;
        // try to use intl (but it's not sure we can access it)
        if (typeof Intl.NumberFormat.prototype.formatToParts !== "undefined") {
            try {
                // try to get the seperator result
                const seperator = Intl.NumberFormat(language)
                    .formatToParts(toFromat)
                    .find((part) => part.type === type).value;
                if (seperator) {
                    return seperator;
                }
            } catch (error) { }
        }
        return "";
    }

    /**
     * it takes a number, formats it to a locale, and then extracts the separators from the formatted
     * string
     *
     * @param type
     * @param language
     * @returns
     */
    private getNumberSeparatorByRegEx(type: "decimal" | "group", language: string): string {
        // number to do magic with ;)
        const toFromat = 1000.1;
        const matches = /^[0-9]+([^0-9]*)[0-9]+([^0-9]+)[0-9]+$/gim.exec(toFromat.toLocaleString(language));
        if (matches === null) {
            return "";
        }
        if (type === "group") {
            return matches[1];
        }
        if (type === "decimal") {
            return matches[2];
        }
        return "";
    }
    public async updateLanguage(lang:string){
        this.useLanguage(lang,true);
        this.setLocalStorageLanguage(lang)
        //update the user language.
    }
}
