import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security';
import { SocialAuthenticationService } from '../front/core/social';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../core/security/service/oauth2.config';
import { MemberService } from '../core/member';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private auth: AuthenticationService, private route: Router, private oauth: OAuthService, public memberService: MemberService, public social: SocialAuthenticationService) { }

  async ngOnInit() {
    //This component refers to the pkce callback. It wil be run the first after receiving the PKCE CODE.
    if (this.oauth.hasValidAccessToken()){
      this.social.isVerifyingObservable().subscribe(()=>{
        if (this.social.getIsVerified()){
          this.memberService.reload().subscribe(()=>{
            this.route.navigate(["arena"])
          })
        }
      })
      await this.social.verifyYoureToken(this.oauth.getAccessToken())
    } 
  }

}
