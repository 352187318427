import { OAuthService } from "angular-oauth2-oidc";
import { AuthenticationService } from "../service/authentication.service";
import { authCodeFlowConfig } from "../service/oauth2.config";
import { firstValueFrom } from "rxjs";
import { SocialAuthenticationService } from "src/app/front/core/social";
import { MemberService } from "../../member";

/**
 * provides an initializer which tries to auth the user
 * by using the refresh method from the auth service
 *
 * @param authService
 */
export function AuthenticationInitializer(authService: AuthenticationService, oauth: OAuthService, social: SocialAuthenticationService, member: MemberService) {
    return async () => {
        await oauth.configure(authCodeFlowConfig);
        await oauth.loadDiscoveryDocumentAndTryLogin();
        const token = oauth.getAccessToken();
        social.isVerifyingObservable().subscribe((verification) => {
            if (social.getIsVerified()) {
    
                if (verification.token)
                    authService.setLoginToken(verification.token)
                member.loginRefresh().subscribe()

                // // authService.refresh().subscribe(()=>{
                // // });
            

            }
        })
        if (!oauth.hasValidAccessToken()) {
            await social.verifyYoureToken(token)
        } else {
            member.loginRefresh().subscribe()
        }
    }
}
