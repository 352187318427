import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";


export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.youre.sso.issuer,
  skipIssuerCheck: true,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth/youresso',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: environment.youre.sso.client_id,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid email profile name given_name family_name nickname email_verified picture created_at identifies phone address',

  showDebugInformation: true,
  openUri(uri) {
    window.location.href =  uri + '&action=' + localStorage.getItem('loginAction') || 'login';
  },
};
