<ng-template #modal>
  <div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalDismiss()">
      <span aria-hidden="true">
        <i class="fas fa-times" style="font-size: 24px; padding-right: 10px; padding-top: 10px;">
        </i>
      </span>
    </button>
  </div>
  <div class="modal-header-image">
  </div>

  <div class="modal-body pb-5">
    <div class="row">
      <div class="col-12 col-sm-11 mx-auto text-center">
        <p class="tigerfont header animate__animated animate__fadeInUp animate__delay-500ms">
          {{ 'account.bets.slip.modalLoser.title' | translate: {bettingName: bettingGameName } }}
        </p>
      </div>
      <div class="col-12 mx-auto text-center animate__animated animate__fadeInUp animate__delay-500ms">
        <p class="message">{{ 'account.bets.slip.modalLoser.message' | translate: {placeUser: placeUser } }}
        </p>
      </div>
    </div>
    <div class="image-container" *ngIf="winners.length > 1">
      <div *ngFor="let winner of winners"
        class="col-12 col-auto animate__animated animate__fadeInUp animate__delay-500ms">
        <img class="modal-lose-image" src="/assets/images/modal-icon-lose.png">
        <img class="profile-avatar"
          [ngStyle]="{'background-image': winner.member.picture ? 'url(' + winners[0].member.picture + ')' : 'url(/assets/images/avatar.png)'}">
      </div>
    </div>

    <div class="image-center-container" *ngIf="winners.length === 1">
      <div class="col-12 col-auto animate__animated animate__fadeInUp animate__delay-500ms centered">
        <img class="modal-lose-image" src="/assets/images/modal-icon-lose.png">
        <img class="profile-avatar"
          [ngStyle]="{'background-image': winners[0].member.picture ? 'url(' + winners[0].member.picture + ')' : 'url(/assets/images/avatar.png)'}">

      </div>
    </div>
    <div class="col-12">
      <p class="sub-message">{{ 'account.bets.slip.modalLoser.subMessage' | translate: { jackpot: jackpot,
        winnerUserName: winnerUserName } }}</p>
      <p class="sub-message">{{ 'account.bets.slip.modalLoser.participants' | translate: {numberPlayers: participants}
        }}
      </p>
    </div>
    <div class="col-12 mx-auto center-button text-center animate__animated animate__fadeInUp animate__delay-1500ms"
      style="padding-left:0%">
      <button type="submit" class="special-button button-thanks" (click)="modalDismiss()">
        <div style="font-size: 15px;" class="special-button-text">
          {{'account.bets.slip.modalLoser.button' | translate }}
        </div>
      </button>
      <p class="text-center mt-2 d-block d-md-none d-lg-none d-xl-none"><span class="cursor-pointer"
          (click)="modalDismiss()">{{ 'close' | translate }}</span></p>
    </div>
  </div>
</ng-template>
