<ng-template #modal>
  <div style="background: none" class="modal-header" [class]="data.headerClass">
    <button
      type="button"
      class="close"
      [class]="data.headerClass"
      aria-label="Close"
      (click)="modalDismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="modal-header-image">
  <img  *ngIf="data.image" src="/assets/images/{{  data.image }}" alt="Centered Image">
</div>


  <div class="modal-body pb-5">
    <div class="row">
      <div class="col-12 col-sm-10 mx-auto text-center">
        <span class="feedback-icon" *ngIf="data.icon"
          ><i class="fa" [class]="data.icon"></i></span
        ><!-- fa-check-circle -->
        <h2 style="color: #FFF;

        text-align: center;
        /* Headline 1 */
        font-family: Montserrat;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;" class="tigerfont text-uppercase mb-3">
          <ng-container
            *ngIf="isModalText(data.title); else elseTitleTranslation"
            >{{
              data.title.text | translate : data.title.parameter
            }}</ng-container
          >
          <ng-template #elseTitleTranslation>{{
            data.title | translate
          }}</ng-template>
        </h2>
      </div>
      <div class="col-12 col-sm-10 mx-auto text-center">
        <p style="color: #FFF;

        text-align: center;
        /* Paragraph */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 11px;
        text-transform: lowercase;">
          <ng-container
            *ngIf="isModalText(data.message); else elseMessageTranslation"
            >{{
              data.message.text | translate : data.message.parameter
            }}</ng-container
          >
          <ng-template #elseMessageTranslation>{{
            data.message | translate
          }}</ng-template>
        </p>
        <p class="font-italic" *ngIf="data.note">
          <ng-container
            *ngIf="isModalText(data.note); else elseNoteTranslation"
            >{{
              data.note.text | translate : data.note.parameter
            }}</ng-container
          >
          <ng-template #elseNoteTranslation>{{
            data.note | translate
          }}</ng-template>
        </p>
      </div>
      <div
        class="col-12 col-sm-11 mx-auto text-center"
        *ngIf="!data.hideButtons"
      >
        <button style="margin-left: auto;
        margin-right: auto;

        width: 85%;"
          type="button"
          class="special-button"
          (click)="modalButton()"
        >
          <ng-container
            *ngIf="
              isModalText(data.buttonConfirm);
              else elseButtonConfirmTranslation
            "
            >
            <div  style="  font-size: 22px;max-width: none;" class="special-button-text">            {{
              data.buttonConfirm.text | translate : data.buttonConfirm.parameter
            }}</div>


            </ng-container
          >
          <ng-template #elseButtonConfirmTranslation>
            <div style="  font-size: 22px;max-width: none;" class="special-button-text">
            {{
            data.buttonConfirm | translate
          }} </div>
        </ng-template>
        </button>
      </div>
    </div>
  </div>
</ng-template>
