import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SliderComponent
    }
  ]
})
export class SliderComponent implements ControlValueAccessor {

  constructor() { }

  @Input() min = 0;
  @Input() max = 100;
  @Input() value = 0;
  @Input() step = 25;

  onChange = (value: any) => { };
  onTouched = () => { };
  writeValue(obj: any): void {
    this.value = parseInt(obj.toString());
    this.onChange(this.value);
    this.onTouched();
    this.updateView();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  nativeValueChange() {
    this.writeValue(this.value)
  }


  backgroundSize!: string;

  updateView() {
    const min = this.min;
    const max = this.max;
    const val = this.value;
    this.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
  }





}

