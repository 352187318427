import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { MemberInterface } from '../interface/member.interface';


/**
 * Basic Api Url for member actions
 */
const apiProfileUrl = `${environment.api.request}/member/profile`;


@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    /**
     * the current profile
     */
    private profileSubject: BehaviorSubject<MemberInterface>;

    /**
     * observable member genereted from profileSubject
     */
    private profileObservable: Observable<MemberInterface>;

    /**
     * indicates if a profile is loading
     */
    private isProfileLoading: boolean = false;

    /**
     * last update at (we wil reload the profile info if they are older then a minute)
     */
    private lastUpdateAt: Date;


    /**
     * setup dependencies, init variables and subscripbe to events
     *
     * @param http
     */
    constructor(
        private httpService: HttpService
    ) {
        this.profileSubject = new BehaviorSubject<MemberInterface>(null);
        this.profileObservable = this.profileSubject.asObservable();
        this.lastUpdateAt = new Date();
    }

    /**
     * returns an observable "member" object
     *
     * @param username
     */
    public getProfile(username: string): Observable<MemberInterface> {
        if (!this.isProfileLoading && (this.profileSubject.value?.username !== username || (this.lastUpdateAt.getTime() + 60000) < Date.now())) {
            return this.load(username);
        }
        return this.profileObservable.pipe(filter((profile: MemberInterface) => profile !== null), first());
    }

    /**
     * loads logged member from api
     *
     * @param username
     */
    public load(username: string): Observable<MemberInterface> {
        return this.requestProfile("/" + username);
    }

    /**
     * request member data from api
     *
     * @param url
     * @param params
     */
    private requestProfile(url: string, params?: { [key: string]: string }): Observable<MemberInterface> {
        this.isProfileLoading = true;
        this.profileSubject.next(null);
        const encodedUsername = encodeURIComponent(url);
        return this.httpService.get<MemberInterface>(apiProfileUrl + encodedUsername, params).pipe(
            map((profile: MemberInterface) => {
                profile.changeDate = new Date(profile?.changeDate || Date.now());
                return profile;
            }),
            tap((profile: MemberInterface) => {
                this.profileSubject.next(profile);
                this.isProfileLoading = false;
            }));
    }
}
