/**
 * all types which an modal can hanlde
 */
export enum ModalType {
    Default = "default",
    Success = "success",
    Error = "error",
    Confirm = "confirm",
    InjectComponent = "inject-component",

    BetSlipBestOf = "betslip-bestof",
    TaskComplete = "task-complete",
    PasswordReset = "password-reset",
    SeasonRanking = "season-ranking",
    SeasonWinLadder = "season-winladder",
    Login = "login",
    Register = "register",
    Payment = "payment",
    BetSlip = "bet-slip",
    BetSlipDetail = "bet-slip-detail",
    BetsWin = "bets-win",
    SystemBet = "system-bets",
    VideoAds = "video-ads",

    Tutorial = "tutorial",
    Loading = "loading",

    bettingGameWinner = "betting-game-win",
    bettingGameLoser = "betting-game-loser"
}
