import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { listLocales } from 'ngx-bootstrap/chronos';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BettingGameWinMode } from 'src/app/front/core/betting-game/enum/betting-game-win-mode.enum';
import { MemberService } from '../../../../../../core/member';
import { TranslationService } from '../../../../../../core/translation';
import { FromHelper } from '../../../../../../shared/form';
import {
  BettingGameInterface,
  BettingGameLeagueSelectInterface,
  BettingGameLeagueService,
  BettingGameService,
} from '../../../../../core/betting-game';
import { MemberInterface } from '../../../../../core/member';
import { ModalService } from '../../../../../shared/modal';
import { ModalComponent } from '../../../../../shared/modal/component/modal/modal.component';
import { filter } from 'rxjs/operators';
import {
  ListService,
  ListInterface,
  ListFilterInterface,
} from '../../../../../core/game';
import { ListFilterOrderEnum } from '../../../../../core/game';
import { ListFilterCategoryEnum } from '../../../../../core/game';
import { ListFilterSportEnum } from '../../../../../core/game';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-bettinggame-dashboard-includes-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.scss'],
})
export class CreationComponent implements OnInit, OnDestroy {


  public filter: ListFilterInterface;
  public useGlobalFilter: boolean;
  public hasGames: boolean;
  public isFirstLoadingComplete: boolean;
  public loadedAt: Date;
  public isLoading: boolean;
  protected gameListSubscription: Subscription;
  isNewProfile: boolean = true;


  /**
   * tracks if the formular is currently saving
   */
  public isSaving: boolean;

  /**
   * tracks if the formular is enabled or not
   */
  public isEnabled: boolean;

  /**
   * an error string
   */
  public error: string;

  /**
   * the formular to use
   */
  public creationFormular: FormGroup;

  /**
   * member subscription
   */
  private memberSubscription: Subscription;

  /**
   * all known selectable leagues
   */
  public leaguesSelect: BettingGameLeagueSelectInterface;

  /**
   * the modal containing this component
   */
  private parentModal: ModalComponent;

  constructor(
    private leagueService: BettingGameLeagueService,
    private bettingGameService: BettingGameService,
    private translationService: TranslationService,
    private bsLocaleService: BsLocaleService,
    private memberService: MemberService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastrService,
    private cdRef: ChangeDetectorRef,
    protected listService: ListService
  ) {
    this.isLoading = true;
    this.loadedAt = new Date();
  }

  public ngOnInit(): void {
    this.loadLeagues();
    this.buildFormular();
    this.subscribeMemberObservable();
    this.creationFormular.valueChanges.subscribe((s) => {
      this.filter = {
        category: ListFilterCategoryEnum.League,
        order: ListFilterOrderEnum.Time,
        sport: ListFilterSportEnum.Football,
        perPage: 50,
        page: 1,
        leagueId: +this.creationFormular.value.leagues[0],
      };

      // Trigger change detection to update the view
      this.cdRef.detectChanges();
    });

    this.setBsLocale();
  }

  public ngOnDestroy(): void {
    this.unsubscribeMemberObservable();
    this.unsubscribeGameList();
    //this.clearBettingGameLists();
  }

  /**
   * This function sets the parent modal of the current modal
   *
   * @param {ModalComponent} modal - ModalComponent - This is the modal component that will be used
   * to open the modal.
   */
  public setParentModal(modal: ModalComponent): void {
    this.parentModal = modal;
  }

  /**
   * If the form is enabled and not saving, then the user can submit the form
   * @returns A boolean value.
   */
  public canSubmit(): boolean {
    return this.isEnabled && !this.isSaving;
  }

  /**
   * on submit the formular
   */
  public onSubmit(): boolean {
    if (this.creationFormular.invalid) {
      this.onFormErrors();
      return false;
    }
    this.save();
    return false;
  }

  /**
   * close the parent modal
   */
  public close(): void {
    this.parentModal.close();
  }

  /**
   * builds the formular
   */
  private buildFormular(): void {
    this.creationFormular = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(32),
          Validators.pattern(/^[\p{L}0-9]+/iu),
        ],
      ],
      description: [null],
      leagues: this.formBuilder.array([this.formBuilder.control('')]),
      playersLimit: [100],
      isPublic: [true],
      isJackpot: [true],
      winMode: [BettingGameWinMode.TOP3],
      startDate :[null],
      endDate : [null]
      //closeAt: [null, [DateValidator({ onInvalid: null, maxDate: new Date() })]],
    });
    // this.creationFormular.valueChanges.subscribe((s) => {
    //   console.log(this.creationFormular.value);
    // });
  }

  /**
   * subscribe member observable
   */
  private subscribeMemberObservable(): void {
    this.memberSubscription = this.memberService
      .getMemberObservable()
      .pipe(
        tap((member: MemberInterface) => {
          if (member === null) {
            setTimeout(() => this.parentModal.close(), 250);
          }
        })
      )
      .subscribe();
  }

  /**
   * unsubscribe member observable
   */
  private unsubscribeMemberObservable(): void {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
  }

  /**
   * load known lwagues from API
   */
  private loadLeagues(): void {
    this.leagueService.getSelectable().subscribe({
      next: (leaguesSelect: BettingGameLeagueSelectInterface) => {
        // enable the formular and set the selectable leagues
        // we use as options
        this.leaguesSelect = leaguesSelect;
        this.isEnabled = true;
        this.leagueFormControls.controls[0].setValue(
          this.leaguesSelect.popular[0].leagueId
        );
      },
      error: (response: any) => {
        setTimeout(() => {
          this.parentModal.close();
          this.modalService.openError();
        }, 250);
      },
    });
  }

  /**
   * try to create the new bettings game
   */
  private save(): void {
    this.error = '';
    this.isSaving = true;
    const formData = FromHelper.getFormValues(this.creationFormular);
    this.bettingGameService.create(formData).subscribe({
      next: (bettingGame: BettingGameInterface) => {
        this.isSaving = false;
        this.onSaveSuccess(bettingGame);
      },
      error: (response: any) => {
        this.isSaving = false;
        this.onServerErrors(response);
      },
    });
  }

  /**
   * handles form errors
   */
  private onFormErrors(): void {
    const e = FromHelper.getFirstValidationError(this.creationFormular);
    //this.modalService.openError("bettingGame." + errors[i].control + ".error." + errors[i].error);
    this.error = 'bettingGame.default.' + e.control + '.error.' + e.error;
  }

  /**
   * The function is called when the server returns an error and
   * handles the error in the defined way
   *
   * @param {any} response - any
   */
  private onServerErrors(response: any): void {
    // some unknown error occured
    if (!response?.error?.data) {
      this.parentModal.close();
      this.modalService.openError();
      return;
    }
    // switch the error to handle
    // special error messages
    switch (response?.error?.data) {
      // if the member has not enough coins to create
      // a betting group we open a new modal
      case 'coins':
        //this.parentModal.close();
        this.modalService.openDefault({
          title: 'bettingGame.default.coins.error.title',
          message: 'bettingGame.default.coins.error.message',
          note: 'bettingGame.default.coins.error.tip',
          buttonConfirm: 'bettingGame.default.coins.error.button',
          redirectTo: '/shop/coins',
          image: 'coin-shops.png',
        });
        break;
      // any other error is an default error...
      default:
        this.error = 'bettingGame.default.' + response.error.data;
        break;
    }
  }

  /**
   * It closes the modal, and opens a new modal with a success message
   *
   * @param {BettingGameInterface} bettingGame - BettingGameInterface - this is the object that is
   * returned from the server after the save is successful.
   */
  private onSaveSuccess(bettingGame: BettingGameInterface): void {
    this.close();
    this.router.navigate(['/betting-game', bettingGame.uuid, 'prediction']);
    this.modalService.openDefault({
      title: 'bettingGame.dashboard.creation.success.text',
      image: 'betting-sports.png',
    });
    this.memberService.reload().subscribe();
  }

  /**
   * set bootstrap locale to display the correct date names and format them correctly
   */
  private setBsLocale(): void {
    if (
      listLocales().find(
        (locale) => locale === this.translationService.currentLang
      )
    ) {
      this.bsLocaleService.use(this.translationService.currentLang);
    } else {
      this.bsLocaleService.use(this.translationService.getFallbackLanguage());
    }
  }

  /**
   * keep origin order
   *
   * @param a
   * @param b
   * @returns 0
   */
  public orderOrigin(
    a: KeyValue<number, any>,
    b: KeyValue<number, any>
  ): number {
    return 0;
  }

  /**
   * orders by the location or league name
   *
   * @param a
   * @param b
   * @returns 0, -1 or 1.
   */
  public orderByName(
    a: KeyValue<string, any>,
    b: KeyValue<string, any>
  ): number {
    const valA = a.value.locationName || a.value.leagueName;
    const valB = b.value.locationName || b.value.leagueName;
    return valA === valB ? 0 : valA > valB ? 1 : -1;
  }

  public get leagueFormControls() {
    return this.creationFormular.get('leagues') as FormArray;
  }
  public addLeagueControl() {
    if (this.leagueFormControls.length > 5) {
      this.toastService.error("You can't select more than 5 leagues.");
      return;
    }
    this.leagueFormControls.push(
      this.formBuilder.control(
        this.leaguesSelect.popular[0].leagueId || 'loading'
      )
    );
  }

  public removeLeagueControl(leagueControlIndex: number) {
    this.leagueFormControls.removeAt(leagueControlIndex);
  }

  public get maximumPlayersCount() {
    return this.creationFormular.get('playersLimit').value || 'UNLIMITED';
  }

  /**
   * subscribes to gamelist data and requst new
   * data if gamelist is not loading or null
   */

  protected unsubscribeGameList(): void {
    this.gameListSubscription.unsubscribe();
  }
}
