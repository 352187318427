import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";
import { environment } from "src/environments/environment";
import { tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { GameWinnerLoserInfo, Participation } from "src/app/front/core/betting-game/interface/betting-game-winner-loser-interface";

@Component({
    selector: "modal-bet-winner",
    templateUrl: "./modal-bet-winner.component.html",
    styleUrls: ["./modal-bet-winner.component.scss"],
})
export class ModalBetWinnerComponent extends ModalComponent {
    /**
     * a short summery of all won games
     */
    public bettingGameSummaries: GameWinnerLoserInfo;

    /**
     * won coins in total
     */
    public coins: number;

    // Declaring a public property 'bettingGameName' of type string
    public bettingGameName: string;

    /**
     * The total number of participants who took part in the betting game.
     */
    public participants: number;

    /**
     * An array holding the participation details for each winner, including their respective positions and other relevant data.
     */
    public winners: Participation[];

    /**
     * Holds the data related to a member.
     * This includes the username and a picture URL, which may include a timestamp query parameter
     * to ensure the latest image is used (avoiding cache issues).
     */
    public memberdata: {
      username: string;
      picture: string;
    };

    private memberSubscription: Subscription;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected memberService: MemberService,
        protected translationService: TranslationService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ bettingGameSummaries: [] });
        this.setBettingData();
    }

    public ngOnInit(): void {
      this.observeLoginState();
  }
    private observeLoginState(): void {
      this.memberSubscription = this.memberService
          .getMemberObservable()
          .pipe(
              tap((member) => {
                  if (member) {
                    this.memberdata = {
                      username: member.username,
                      picture: this.getPicture(member.picture, member.changeDate),
                  };
                  } else {
                      this.data = null;
                  }
              })
          )
          .subscribe();

  }
    /**
     * reload the member on win open
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    }

    /**
     * overwrite modal button to
     * inject a redirect option
     */
    public modalButton(): void {
        super.modalButton();
    }

    /**
     * Sets the betting data from the provided modal data.
     * This method initializes various properties related to the betting game
     * by extracting them from the 'data' object which is expected to be populated
     * before this method is called.
     */
    private setBettingData(): void {
        // Assign the betting game summaries from the modal data to the component's property
        this.bettingGameSummaries = this.data.bettingGameSummaries;
        // Extract and assign the total coins won from the betting game summaries
        this.coins = this.bettingGameSummaries.coins;
        // Extract and assign the name of the betting game from the betting game summaries
        this.bettingGameName = this.bettingGameSummaries.bettingGame.name;
        // Extract and assign the total number of participants from the betting game summaries
        this.participants = this.bettingGameSummaries.participants;
        // Check if winners data is present in the betting game summaries,
        // if not, initialize it as an empty array
        this.winners = this.bettingGameSummaries.winners !== null ? this.bettingGameSummaries.winners : [];
    }

    /**
     * returns the picture to display via css or an empty string
     *
     * @param picturePath
     * @param changeDate
     */
    private getPicture(picturePath?: string, changeDate?: Date): string {
      return picturePath
          ? "url(" + environment.api.requestImages + picturePath + "?t=" + changeDate.getTime() + ")"
          : "";
    }

    public ngOnDestroy(): void {
      this.memberSubscription.unsubscribe();
    }
}
