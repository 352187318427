import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { BestScoreInterface } from '../interface/besof.interface';

const apiBetBestOfUrl = `${environment.api.request}/bet/bestof`;

@Injectable({
    providedIn: 'root'
})
export class BetSlipBestOfService {
    constructor(
        private httpService: HttpService
    ) {}

    public get(memberId: number): Observable<BestScoreInterface> {
        return this.loadBestOf(memberId);
    }

    /**
     * loads the best of from the api
     *
     * @param betSlipId
     * @returns
     */
    private loadBestOf(memberId: number): Observable<BestScoreInterface> {
        return this.httpService.get<BestScoreInterface>(`${apiBetBestOfUrl}/${memberId}`);
    }
}
