import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, first, switchMap } from "rxjs/operators";
import { StatusCodes } from "http-status-codes";
import { AuthenticationService } from "../service/authentication.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    /**
     * indicates if the refresh token currently
     * become refreshed
     */
    private isRefreshing = false;

    /**
     * behavior subject for the access token, so we can wait for retries
     * after unauthorized until the running refresh request is complete
     */
    private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * urls which cant auto refresh the access token
     */
    private excludedUrls = ["/auth/login", "/auth/autologin", "/auth/logout", "/auth/refresh", "/auth/login/youre"];

    public constructor(
        // inject dependencies
        private authService: AuthenticationService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if there is an authentication, sign the request header with the jwt token
        if (this.authService.hasAuthentication()) {
            request = this.addToken(request, this.authService.getToken());
        }
        // handle the current request and pipe errors
        return next.handle(request).pipe(
            catchError((error) => {
                // if it's an 401 error, we try to refresh the access token...
                if (error instanceof HttpErrorResponse && error.status === StatusCodes.UNAUTHORIZED) {
                    // ...but only if the url is not excluded
                    if (this.canRefresh(request.url)) {
                        return this.tryToRefresh(request, next);
                    }
                    // if not allowed to refresh and the user is logged in
                    // we log the user out and throw the error
                    if (this.authService.hasAuthentication()) {
                        return this.handleUnauthorized(request, error);
                    }
                }
                // if any other http error, just throw it
                return throwError(error);
            })
        );
    }

    /**
     * try to refresh the access token and retry the
     * unauthorized request
     *
     * @param request
     * @param next
     */
    public tryToRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.tokenSubject.next(null);
            // get new access token and retry the request
            return this.authService.refresh().pipe(
                // if an error occured while refreshing, just note
                // that we are not refreshing anymore and return
                // the received error to prevent that the interecptor
                // stops to handle after an error
                catchError((err) => {
                    this.isRefreshing = false;
                    return err;
                }),
                // after refreshing the user, save the new token and go on
                switchMap(() => {
                    this.isRefreshing = false;
                    this.tokenSubject.next(this.authService.getToken());
                    return next.handle(this.addToken(request, this.authService.getToken()));
                })
            );
        }
        // wait until the refresh token is set before try to
        // reload the request...
        return this.tokenSubject.pipe(
            filter((token) => token != null),
            first(),
            switchMap((token) => {
                return next.handle(this.addToken(request, token));
            })
        );
    }

    /**
     * handle unauthorized request by loggin the user
     * out and throw the HttpErrorResponse
     *
     * @param request
     * @param error
     */
    public handleUnauthorized(
        request: HttpRequest<any>,
        error: HttpErrorResponse
    ): Observable<HttpEvent<any>> {
        return this.authService.logout().pipe(
            switchMap(() => {
                return throwError(error);
            })
        );
    }

    /**
     * add token to request header
     *
     * @param request
     * @param token
     */
    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    /**
     * checks if we can retry to load
     * the url after try to refreshing
     * the access token
     *
     * @param url
     */
    private canRefresh(url: string): boolean {
        return (
            undefined ===
            this.excludedUrls.find((testUrl: string) => {
                return url.endsWith(testUrl);
            })
        );
    }
}
