import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

const routes: Routes = [
  {
    path: 'static',
    loadChildren: () =>
      import('./front/page/static.module').then((m) => m.StaticModule),
  },
  {
    path: 'tcp',
    loadChildren: () => import('./tcp/tcp.module').then((m) => m.TcpModule),
  },
  {
    path: "auth/youresso",
    component: AuthCallbackComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('./front/front.module').then((m) => m.FrontModule),
  },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
