import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ListInterface } from '../interface/list.interface';
import { ListFilterInterface } from '../interface/list-filter.interface';
import { ListFilterCategoryEnum } from '../model/list-filter-category.enum';
import {
  LeagueInterface,
  ListFilterOrderEnum,
  ListFilterSportEnum,
  ListLoadingInterface,
} from '..';
import { HttpService } from '../../../../core/http';
import { Router } from '@angular/router';
import merge from 'deepmerge';

/**
 * Basic Api Url for game list
 */
const apiGameListUrl = `${environment.api.request}/game/list`;

@Injectable({
  providedIn: 'root',
})
export class ListService {
  /**
   * game list data
   */
  private listSubject: BehaviorSubject<ListInterface>;

  /**
   * filter subject
   */
  private filterSubject: BehaviorSubject<ListFilterInterface>;

  /**
   * indicates the loading state
   */
  private isLoadingSubject: BehaviorSubject<ListLoadingInterface>;

  /**
   * default filter for gamelist
   */
  private filterDefault: ListFilterInterface = {
    category: ListFilterCategoryEnum.All,
    order: ListFilterOrderEnum.Time,
    sport: ListFilterSportEnum.Football,
    perPage: 50,
    page: 1,
  };

  public constructor(private httpService: HttpService, private router: Router) {
    this.listSubject = new BehaviorSubject<ListInterface>(null);
    this.filterSubject = new BehaviorSubject<ListFilterInterface>(
      this.getFilterDefault()
    );
    this.isLoadingSubject = new BehaviorSubject<ListLoadingInterface>({
      filter: null,
      loading: false,
    });
  }

  /**
   * compares to filters again each other
   *
   * @param mainFilter
   * @param compareFilter
   * @returns
   */
  public compareFilter(
    mainFilter?: ListFilterInterface,
    compareFilter?: ListFilterInterface
  ): boolean {
    mainFilter = mainFilter || {};
    compareFilter = compareFilter || {};
    // compare the amount of filter settings
    if (Object.keys(mainFilter).length !== Object.keys(compareFilter).length) {
      return false;
    }
    // compare the single settings
    for (const filterKey in mainFilter) {
      if (mainFilter[filterKey] !== compareFilter[filterKey]) {
        return false;
      }
    }
    return true;
  }

  /**
   * returns an observable for the current loading state
   *
   * @returns an boolean observable
   */
  public getIsLoadingObservable(): Observable<ListLoadingInterface> {
    return this.isLoadingSubject.asObservable();
  }

  /**
   * returns an observable for gamelist data
   *
   * @returns an "not null" observable
   */
  public getGameListObservable(): Observable<ListInterface> {
    return this.listSubject
      .asObservable()
      .pipe(filter((gameList: ListInterface) => gameList !== null));
  }

  /**
   * removes the games from the list
   */
  public clearPage(): void {
    // push an empty list as new game list subject
    this.listSubject.next({ ...this.listSubject.value, games: [] });
  }

  /**
   * sets the order filter option by resetting the
   * complete gamelist and modifies the current filter
   *
   * @param order
   */
  public setOrder(order: ListFilterOrderEnum): void {
    this.clearPage();
    this.modifyFilter({ order: order });
  }

  /**
   * sets the league filter option by setting up
   * the correct filter only by passing the league
   *
   * @param league
   */
  public setLeague(league: LeagueInterface): void {
    this.setFilter({
      category: ListFilterCategoryEnum.League,
      leagueId: league.leagueId,
      sport: <ListFilterSportEnum>league.sportName,
    });
  }

  /**
   * sets the category filter option by setting up
   * the correct filter only by passing the category
   *
   * @param category
   */
  public setCategory(category: ListFilterCategoryEnum): void {
    // get current sport filter if set, else we use the default
    const sportFilter =
      this.getFilterSnapshot().sport || this.getFilterDefault().sport;
    // sets the new filter
    this.setFilter({
      category: category,
      sport: sportFilter,
    });
  }

  /**
   * sets the passed filter and loads the gamelist with it
   *
   * @param filter
   * @param temporary
   */
  public setFilter(filter?: ListFilterInterface, temporary?: boolean): void {
    // apply the filter and navigate to gamelist
    this.useFilter(filter, temporary);
    this.router.navigate(['/bet']);
  }

  /**
   * modify the passed filter and loads the gamelist with it
   *
   * @param filter
   */
  public modifyFilter(filter?: ListFilterInterface): void {
    // modify and apply the filter and rest the page to 1 if
    // there is not a page given by the passed filter
    const page = filter?.page || 1;
    this.setFilter({ ...this.getFilterSnapshot(), ...filter, page: page });
  }

  /**
   * returns current filter
   *
   * @returns current GameFilterInterface
   */
  public getFilterSnapshot(): ListFilterInterface {
    return this.filterSubject.value;
  }

  /**
   * get default filter options as copy
   * to prevent loosing the settings
   *
   * @returns
   */
  public getFilterDefault(): ListFilterInterface {
    return merge({}, this.filterDefault);
  }

  /**
   * returns an observable for the gamelist filter
   *
   * @returns
   */
  public getFilterObservable(): Observable<ListFilterInterface> {
    return this.filterSubject.asObservable();
  }

  /**
   * reload the game list with the current filter
   *
   * @returns
   */
  public reload(): Observable<ListInterface> {
    return this.loadGameList();
  }

  /**
   * returns a new loaded gamelist
   *
   * @param gameListFilter
   * @returns
   */
  public loadGameList(
    gameListFilter?: ListFilterInterface,
    temporary?: boolean
  ): Observable<ListInterface> {
    // if the same filter is loading (and we know it) we can skip request and wait for it
    const loadingState: Partial<ListLoadingInterface> =
      this.isLoadingSubject.value || {};
    if (
      loadingState.loading &&
      this.compareFilter(gameListFilter, loadingState.filter)
    ) {
      // wait until a request with the same filter is done
      return this.getGameListObservable().pipe(
        filter((gameList: ListInterface) =>
          this.compareFilter(gameListFilter, gameList.filter)
        ),
        first()
      );
    }
    // start a new laoding request and return it
    return this.requestGameList(
      gameListFilter || this.getFilterSnapshot(),
      temporary
    );
  }

  /**
   * reset currently loaded game list
   */
  public reset(): void {
    this.filterSubject.next(this.getFilterDefault());
    this.clear();
  }

  /**
   * clears the gamelist but keeps the filter
   */
  public clear(): void {
    this.listSubject.next(null);
  }

  /**
   * use the passed filter, extends it with the default filter and loads the gamelist
   *
   * @param filter
   * @param temporary
   */
  public useFilter(filter?: ListFilterInterface, temporary?: boolean): void {
    const filterNew = { ...this.getFilterDefault(), ...filter };
    this.loadGameList(filterNew, temporary).subscribe();
  }

  /**
   * request game list from api
   *
   * @param filter
   * @param temporary
   * @returns
   */
  private requestGameList(
    filter: ListFilterInterface,
    temporary?: boolean
  ): Observable<ListInterface> {
    // prepare the list and set loading
    this.listSubject.next(null);
    this.isLoadingSubject.next({
      loading: true,
      filter: filter,
    });
    // set new global filter if necessary
    if (!temporary) {
      this.filterSubject.next(filter);
    }
    // load the list and set the result
    return this.httpService
      .post<ListInterface>(`${apiGameListUrl}`, { filter: filter })
      .pipe(
        tap((list: ListInterface) => {
          this.listSubject.next(list);
          this.isLoadingSubject.next({
            loading: false,
            filter: filter,
          });
        })
      );
  }
}
